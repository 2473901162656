.form-item{
  position: relative;
  height: fit-content;

  width: fit-content;
  align-self: end;


  :nth-of-type(2){
    font-size: 12px;
    color: #f6f6f6;

    opacity: 0.75;
  }

  label{
    position: absolute;
    color: #f6f6f6;

    bottom: 20px;
    left: 10px;

    font-size: 16px;

    transition: bottom 0.25s ease, font-size 0.25s ease, left 0.25s ease, top 0.25s ease;
    cursor: text;
  }

  input{
    background: none;
    border: none;

    color: #f6f6f6;
    font-size: 20px;
    
    height: 35px;
    width: 260px;

    border-bottom: 1px solid #4b4b4b;
  }

  textarea{
    height: 340px;
    width: 100%;

    background: none;

    color: #f6f6f6;

    font-size: 14px;

    padding: 25px;
  
    border: 1px solid #4b4b4b;
    border-radius: 15px;

    resize: none;
  }
}

@media (max-width: 1180px) {
  .form-item {
    width: 100%;

    input {
      width: 100%;
    }
  }
}
