@import '../variables';
@import '../animations.module.scss';

.right{
  background: #131313;
  justify-self: end;

  height: auto;
  padding-right: 55px;
  padding-left: 55px;
}

.filters{
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  
  position: sticky;
  top: 30px;
  
  animation: appearFromRight 1s ease, appear 1s ease;
}

.navi{
  position: sticky;

  display: flex;
  flex-direction: column;

  top: 350px;
  padding: 11px;

  border: 1px solid #313131;
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.9);
  
  animation: appearFromRight 1s ease, appear 1s ease;

  :last-child{
    margin-bottom: 0px !important;
  }
}

.open-sidebar-button{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 20px;
  width: fit-content;

  margin-left: 20px;

  :nth-child(1), :nth-child(2), :nth-child(3){
    height: 2px;
    background: #f6f6f6;
    opacity: 0.5;

    border-radius: 10px
  }

  :nth-child(1){
    width: 15px;    
  }

  :nth-child(2){
    width: 30px;    
  }

  :nth-child(3){
    width: 20px;    
  }
}

@media (max-width: 1500px) {
  .right{
    padding: 0px 40px;
  }
}

@media (max-width: 950px) {
  .right{
    position: sticky;
    top: 0px;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: space-between;

    order: 1;
    height: fit-content;
    width: 100%;

    padding: 18px 0px;
    box-shadow: 0px 4px 11.1px 3px rgba(0, 0, 0, 0.35);

    border-bottom: 1px solid #2B2B2B;
    animation: appearFromRight 1s ease, appear 1s ease;
  }
  
  .filters{
    display: flex;

    height: fit-content;
    top: 0px;
  }

  .navi{
    top: 0px;
    flex-direction: row;

    height: fit-content;

    padding: 0px;

    border: none;
    box-shadow: none;
  }
}