.sidebar-wrapper{
  position: absolute;
  top: 0px;
  left: -100%;

  height: 100%;
  width: 100%;

  background: #0E0E0E;
  z-index: 6;

  transition: left 0.5s ease;
}

.open{
  left: 0px;
}

.sidebar{
  position: sticky;
  top: 0px;
}

.close-sidebar-wrapper{
  display: flex;
  align-items: center;
  justify-content: end;

  width: 100%;
  height: 70px;
}

.close-sidebar-button{
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;

  margin-right: 15px;

  width: 40px;
  height: 40px;
 
  :nth-child(1), :nth-child(2){
    position: absolute;

    background: #f6f6f6;
    opacity: 0.5;

    width: 30px;
    height: 2px;
    transform-origin: center;
  }

  :nth-child(1){
    transform: rotate(45deg);
  }

  :nth-child(2){
    transform: rotate(135deg);
  }
}

.sidebar-filters-wrapper{
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding-left: 60px;

  height: fit-content;

  >div{
    margin-bottom: 15px !important;
  }
}