@import '../animations.module.scss';

.one{
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #131313;

  padding-top: 80px;
  padding-bottom: 50px;

  .content{
    width: 100%;
    max-width: 850px;
    position: relative;
    animation: appearFromTop 1s ease, appear 1s ease;

    .navi-text-on-middle{
      border: 1px solid #3f3f3f;
      width: fit-content;
    
      padding: 9px 19px;
      border-radius: 13px;
    
      font-size: 16px;
      font-weight: 400;
    
      margin-bottom: 18px;
    }
  }

  .main-text{
    font-size: 80px;
    line-height: 112%;
    letter-spacing: -2.5px;
    font-weight: 280;

    span{
      color: #66a549;
      font-weight: 400;
    }
  }

  .middle-text{
    display: flex;
    
    font-size: 16px;
    font-weight: 400;
    color: #888888;

    align-items: center;

    padding: 50px 5px;
  }

  .skills-wrapper{
    display: flex;
    flex-wrap: wrap;
 
    margin-top: 75px;

    width: 100%;
    max-width: 850px; 

    gap: 15px;
  }

  .new-skills-outer{
    position: relative;
    display: flex;
    justify-content: space-between;

    background: #0F0F0F;

    height: 260px;
    width: 850px;

    margin-top: 100px;

    border: 1px solid #292929;
    border-radius: 30px;

    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.9);
    
    >:nth-child(1), >:nth-child(2){
      display: flex;
      justify-content: center;
      align-items: center;

      width: 75px;
    }

    .random-word, .website-text{
      font-size: 24px;
      font-weight: 700;

      color: #f6f6f6;
      opacity: 0.2;

      letter-spacing: 1px;
    }

    .random-word{
      transform: rotate(270deg);
    }

    .website-text{
      transform: rotate(270deg);
    }
  }

  .new-skills-inner{
    position: absolute;

    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    border: 1px solid #414141;
    border-radius: 30px;

    background: #131313;

    height: 260px;
    width: 780px;

    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.2), 4px 0px 8px rgba(0, 0, 0, 0.2);
    animation: move 2s infinite;

    >:nth-child(1), >:nth-child(2), >:nth-child(3) {
      padding-top: 15px;
    }

    >:nth-child(4), >:nth-child(5), >:nth-child(6) {
      padding-bottom: 15px;
    }

    >div {
      display: flex;
      align-items: center;
      justify-content: start;

      margin-left: 60px;

      font-size: 20px;
      font-weight: 500;

      opacity: 0.7;

      height: 120px;

      img{
        margin-right: 15px;
      }
    }

    .new-skill{
      font-size: 20px;
      font-weight: 400
    }
  }
}

@media (max-width: 1500px) {
  .one{
    border-bottom: none;
  }

  .one .main-text{
    font-size: 50px;
  }
  
  .one .middle-text {
    font-size: 14px;
    width: 100%;
  }
}

@keyframes move {
  0% {
    left: 0;
    animation-timing-function: ease;
  }
  50% {
    left: 70px;
    animation-timing-function: linear;
  }
  100% {
    left: 0;
  }
}

@media (max-width: 950px) {
  .one{
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;

    animation: appearFromLeft 1s, ease appear 1s;
  }

  .one .main-text {
    font-size: 45px;
    width: 100%;

    text-align: center;
  }

  .one .middle-text {
    flex-direction: column;
    justify-content: center;
    padding: 50px 0px;
    width: 100%;
  }

  .one .skills-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;

    width: 100%;

    margin-top: 0px;
  }
}
