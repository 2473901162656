@import '../variables';

.skill{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-width: 115px;
  max-width: 123px;

  color: #888888;
  background-color: #1A1A1A;

  font-weight: 400;
  padding: 35px 0px 30px 0px;
  
  border: 1px solid rgb(36, 36, 36);
  border-radius: 30px;

  flex: 1 1 100px;
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out; 

  img{
    position: absolute;
  }

  >div{
    margin-top: 90px;
  }

  &:hover{
    transform: translateY(-10%);
    border: 1px solid #7c3b3b;
    background: #1E1414;
    color: var(--red);
  }
}