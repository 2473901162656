@import '../variables';

.price-block{
  position: relative;
  flex: 1;

  min-width: 300px;
  height: 590px;

  border: 1px solid #3f3f3f;
  border-radius: 25px;

  padding: 40px 45px;
  transition: border 0.35s ease;

  cursor: pointer;

  .title, .benefits, .price{
    color: #F6F6F6;
  }

  .title{
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 20px;
    font-weight: 700;

    margin-left: -45px;
    margin-right: -45px;
    margin-top: -40px;

    padding: 30px 50px;

    border-radius: 25px 25px 0px 0px;
  }

  .price-per-hour{
    font-size: 20px;
    font-weight: 400;

    color: #707070;
    padding: 20px 0px;

    border-bottom: 1px solid #3f3f3f;
  }

  .benefits{
    font-size: 14px;
    padding: 50px 0px;

    height: 320px;

    p{
      font-weight: 400;
      margin-bottom: 25px;
    }
  }

  .choose{
    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;
    background: var(--red);

    font-weight: 600;
    font-size: 16px;

    border-bottom: 5px solid #AA4C4C;
    border-radius: 15px;

    padding: 10px;
    letter-spacing: 0.2px;

    cursor: pointer;
    user-select: none;

    &:hover{
      background: #c45c5c;
    }

    &:active{
      background: #ca5f5f;
      padding: 12.5px;
      border-bottom: none;
    }
  }
}
