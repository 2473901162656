@import '../animations.module.scss';

.two{
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #131313;

  padding-top: 120px;
  padding-bottom: 50px;

  .content{
    width: 100%;
    max-width: 850px;
    position: relative;
    transition: opacity 1.5s ease, transform 0.75s ease;

    .navi-text-on-middle{
      border: 1px solid #3f3f3f;
      width: fit-content;
    
      padding: 9px 19px;
      border-radius: 13px;
    
      font-size: 16px;
      font-weight: 400;
    
      margin-bottom: 18px;
    }
  }

  .work-block{
    position: relative;
    
    border-radius: 15px;
    cursor: pointer;

    img:nth-of-type(1){
      opacity: 0.1;
    }

    &:hover div{
      border: 1px solid rgba(255, 255, 255, 0);
    }

    &:hover img{
      opacity: 0.9;
      transform: translateX(12px) translateY(-12px);
      box-shadow: 0px 4px 11.1px 15px rgba(0, 0, 0, 0.15);
    }

    img{
      display: block;
      height: 100%;
      width: 100%;

      border-radius: 15px;

      opacity: 0.20;
      transition: opacity 0.5s ease, transform 0.5s ease;
    }

    div{
      position: absolute;
      
      border: 1px solid #444444;
      border-radius: 15px;
      
      height: 100%;
      width: 100%;

      top: -12px;
      left: 12px;

      transition: border 0.5s ease;
    }

    div:nth-of-type(2){
      background: #f6f6f6;
      opacity: 0;

      height: 40%;
      bottom: 10px;
      
      width: 100%;

      z-index: 1;

      top: auto;
      border: none;
      border-radius: 0px 0px 15px 15px;

      transform: translateX(10px) translateY(-10px);
      transition: opacity 0.5s ease, transform 0.5s ease, background 0.5s ease;
    }

    &:hover div:nth-of-type(2){
      transform: translateY(0px);
      transform: translateX(0px);
      opacity: 0.95;
      background: #1F1F1F;
    }

    &:hover .white-to-black{
      color: #3a3a3a;
      transition: color 0.5s ease;
    }

    p{
      position: absolute;
      left: 60px;
      left: 8%;
      z-index: 2;
    }

    p:nth-of-type(1) {
      font-size: 36px;
      font-weight: 800;

      bottom: 90px;
      bottom: 22.5%;
    }

    p:nth-of-type(2) {
      bottom: 55px;
      bottom: 13.5%;
      font-size: 20px;
    }

    p:nth-of-type(3) {
      left: auto;
      bottom: 60px;
      bottom: 13.5%;
      right: 30px;
      right: 4%;

      font-size: 24px;
      font-weight: 500;

      width: fit-content;
    }
  }
}

@media (max-width: 1500px) {
  .two{
    border-bottom: none;
  }

  .two .work-wrapper{
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 550px;

    :nth-child(3){
      grid-column: span 1;
    }
  }
}


@media (max-width: 1180px){
  .two .work-block {
    p:nth-last-of-type(1){
      font-size: 20px;
    }
    p:nth-last-of-type(2){
      font-size: 16px;
      bottom: 17%;
    }
    p:nth-last-of-type(3){
      bottom: 30%;
      font-size: 28px;
    }
  
    div:nth-of-type(2){
      height: 50%;
    }
  }
}

@media (max-width: 950px) {
  .two{
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;

    animation: appearFromLeft 1s, ease appear 1s;
  }

  .two .work-wrapper{
    grid-template-columns: repeat(auto-fit, minmax(200px, 800px));
    width: auto;

    .work-block{
      padding: 20px;
    }

    :nth-child(3){
      grid-column: span 1;
    }

    .work-block .work-upper{
      margin-bottom: 20px;

      div :nth-child(1){
        font-size: 16px;
        margin-bottom: 5px;
      }

      div :nth-child(2){
        color: #777777;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .two .work-block {
    p:nth-last-of-type(1){
      font-size: 24px;
    }
    p:nth-last-of-type(2){
      font-size: 20px;
      bottom: 15%;
    }
    p:nth-last-of-type(3){
      bottom: 25%;
      font-size: 32px;
    }
  }
}

@media (max-width: 650px) {
  .two .work-block {
    p:nth-last-of-type(1){
      font-size: 20px;
    }
    p:nth-last-of-type(2){
      font-size: 16px;
      bottom: 15%;
    }
    p:nth-last-of-type(3){
      bottom: 25%;
      font-size: 28px;
    }
  }
}

@media (max-width: 500px) {
  .two .work-block {
    p:nth-last-of-type(1){
      font-size: 16px;
    }
    p:nth-last-of-type(2){
      font-size: 12px;
      bottom: 15%;
    }
    p:nth-last-of-type(3){
      bottom: 25%;
      font-size: 24px;
    }
  }
}