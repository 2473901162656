@keyframes appearFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes appearFromRight {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes appearFromTop {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes appearFromBottom {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}