

.filter{
  position: relative;

  display: flex;
  flex-direction: column;

  align-items: center;

  background: #131313;

  height: 60px;
  width: 60px;

  border: 1px solid #363636;
  border-radius: 12px;

  transition: height 0.2s ease;

  z-index: 1;
  margin-bottom: 10px;

 >div{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #bebebe;
  font-size: 14px;
  font-weight: 500;

  height: 58px;
  width: 100%;

  border-radius: 12px;

  transition: opacity 0.2s ease, margin-top 0.2s ease;
  user-select: none;

  cursor: pointer;

  &:hover{
    background: #1a1a1a;
  }

  &:active{
    background: #202020;
  }

  img {
    width: 22px;
    margin-right: 0.5px;
  }
 }
}

@media (max-width: 1680px) {
  .reduce-width{
    width: 220px !important;
  }

  .reduce-width2{
    width: 210px !important;
    border: 1px solid red;
  }

  .full-width{
    width: 98% !important;
  }
}

