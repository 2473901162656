* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent; /*To remove the blue background when tapping on links & icons on phone*/
  font-family: 'Roboto Slab', serif;
}

*:focus,
*:active {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

html, body, #root{
  height: 100%;
  /* because of transition animation, putting it in .app will remove the sticky from placeholder*/
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noUserInteraction {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

html {
  scroll-behavior: smooth;
  
  font-weight: 300;
  background: #0E0E0E;
  
}
