.letterbox-wrapper{
  display: flex;

  padding: 10px 0px;
}

.letterBox{
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;

  width: 24px;
  height: 29px;

  margin-right: 3px;

  border-radius: 5px;
  border: 1px solid #292929;

  :nth-child(1){
    z-index: 1;
  }

  :nth-child(2){
    position: absolute;

    top: 0px;
    background: #161616;

    height: 50%;
    width: 100%;

    z-index: 0;

    border-bottom: 1px solid #1b1b1b;
    border-radius: 5px 5px 0px 0px;
  }

}